// Basic
//////////////////////////////////////////////////////////

// compass
@import "lib/compass";

// Core
@import "variables";
@import "mixins";

// Normalize
@import "normalize";

// reboot
@import "reboot";

// Utility classes
@import "utilities";

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

body {
	background-color: #fff;
	font-family: $font-family;
	font-size: $font-size;
	color: #000;
	min-width: $base-width + 20px;
  letter-spacing: 0.1px;
	line-height: 2em;
	overflow-x: hidden;
	@include media-breakpoint-down(xs) {
		min-width: 100%;
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// animations
//
//
.style-hover-opacity,
.btn, a img,
.global-menu-detail__menu a {
	@include transition(.3s);
	&:hover {
		text-decoration: none;
		opacity: 0.8;
	}
}
.style-link-black-to-green {
	@include transition(.3s);
	color: #333;
	&:hover {
		text-decoration: none;
		// opacity: 0.8;
		color: #077877;
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// public
//
//
.em {
	color: #61c6e7;
}
.al-c {
	text-align: center;
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// btn
//
//
.btn-lists {
	text-align: center;
	@include media-breakpoint-down(xs) {
	}

	> li {
		display: inline-block;
	}
}
.btn-a {
	position: relative;
	background-color: #61c6e7;
	color: #fff;
	font-size: 24px;
	width: 586px;
	height: 80px;
	max-width: 100%;
	line-height: 80px;
	text-align: center;
	border-radius: 50px;
	display: block;
	@include media-breakpoint-down(xs) {
		width: 100%;
	}
	&:after {
		content: url("../images/icon_rtrif.svg");
		position: absolute;
		width: 10px;
		height: 12px;
		top: -4px;
		right: 30px;
		@include media-breakpoint-down(xs) {
			right: 20px;
		}
	}
	&:hover {
		text-decoration: none;
	}
}
.btn-a-info {
	font-size: 16px;
	@include media-breakpoint-down(xs) {
		font-size: 12px;
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// ttl
//
//
.ttl {
	&__main {
		font-size: 30px;
		font-weight: normal;
		display: block;
    letter-spacing: 5px;
	}
	&__sub {
		font-size: 18px;
		font-weight: normal;
		color: #61c6e7;
		display: block;
		letter-spacing: 4px;
	}
	&__main + &__sub {
		margin-top: 10px;
	}
}
.ttl-b {
	font-size: 24px;
	font-weight: normal;
}
.ttl-c {
	&__main {
		font-size: 24px;
		font-weight: normal;
		display: block;
	}
	&__sub {
		font-size: 14px;
		font-weight: normal;
		color: #61c6e7;
		display: block;
	}
	&__main + &__sub {
		margin-top: 10px;
	}
}
.ttl-set {
	position: relative;
	&__block {
		display: inline-block;
		vertical-align: top;
		&:first-child {
			width: 270px;
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}
		&:nth-child(2) {
			// padding-left: 290px;
			position: absolute;
			top: 0;
			left: 290px;
			@include media-breakpoint-down(xs) {
				position: static;
			}
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// icon - scroll
//
//
.icon-scroll-wrapper {
	position: absolute;
	bottom: 40px;
	left: 50%;
	margin-left: -12px;
	z-index: 50;
}
.icon-scroll {
	width: 24px;
	height: 24px;
	display: block;
}
.icon-scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
	@include transform(rotate(-45deg));
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
.icon-scroll span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.icon-scroll span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
.icon-scroll span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
@-webkit-keyframes sdb {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// card
//
//
.card {
	text-align: left;
	background-color: #fff;
	width: auto;
	min-height: 650px;
	overflow: hidden;
	@include media-breakpoint-down(xs) {
		min-height: 550px;
	}
	&.l-02 {
		min-height: auto;
	}
	a {
		color: #000;
		&:hover {
			text-decoration: none;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	&__content {
		padding: 40px;
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
		// &__ttl {
		// }
		&__detail {
			a {
				color: #61c6e7;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		&__ttl + &__detail {
			margin-top: 20px;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// logo
//
//
.logo-2 {
	text-align: center;
	margin-bottom: 40px;
	&__img {
		display: block;
		img {
			@include media-breakpoint-down(xs) {
				width: 130px;
				height: auto;
			}
		}
	}
	&__txt {
		display: block;
		color: #fff;
		font-size: 10px;
    letter-spacing: 5px;
		@include media-breakpoint-down(xs) {
	    letter-spacing: 2px;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// layout
//
//
.col2 {
	overflow: hidden;
	margin-top: 60px;
	@include media-breakpoint-down(xs) {
		margin-top: 30px;
		padding: 0 20px;
	}
	&__block {
		width: 50%;
		box-sizing: border-box;
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		&__ttl {
			margin-bottom: 40px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 20px;
			}
		}
		&:first-child {
			float: left;
			@include media-breakpoint-down(xs) {
				float: none;
			}
		}
		&:last-child {
			float: right;
			padding-left: 40px;
			@include media-breakpoint-down(xs) {
				float: none;
				padding-left: 0;
				padding-top: 20px;
			}
		}
	}
	&--b {
		.col2__block {
			&:first-child {
				margin-bottom: 40px;
			}
			&:last-child {
				padding-left: 30px;
				@include media-breakpoint-down(xs) {
					padding-left: 0;
				}
			}
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// header
//
//
.header {
	width: 100%;
	position: relative;
	background-color: #1d1d1d;
	&__inner {
		width: 100%;
		margin: 0 auto;
		position: absolute;
		top: 40px;
		z-index: 10;
	}
	&__inner-2 {
		width: 100%;
		height: 170px;
		position: relative;
		margin: 0 auto;
		top: 40px;
		z-index: 10;
	}
	&__logo {
		text-align: center;
		.img {
			display: block;
			img {
				@include media-breakpoint-down(xs) {
					width: 130px;
					height: auto;
				}
			}
		}
		.txt {
			font-size: 10px;
			color: #fff;
			display: block;
    	letter-spacing: 5px;
			@include media-breakpoint-down(xs) {
				letter-spacing: 2px;
			}
		}
		a:hover {
			text-decoration: none;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// global menu
//
//
.global-menu {
	// &__inner {
	// }
	&__btn {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 40px;
		right: 40px;
		z-index: 90;
		@include media-breakpoint-down(xs) {
			top: 30px;
			right: 20px;
		}
	}
}
.global-menu-detail {
	background-color: #000;
	display: none;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	&__inner {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__menu {
		text-align: center;
		> li {
			display: inline-block;
			margin: 0 20px;
			a {
				color: #fff;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&__close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 90;
		@include media-breakpoint-down(xs) {
			top: 30px;
			right: 20px;
		}
		img {
			width: 40px;
			height: 40px;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// hero
//
//
.hero {
	width: 100%;
	height: 100%;
	&__inner {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10;
	}
	&__txt {
		text-align: center;
		&__01 {
			font-size: 60px;
			color: #fff;
			display: block;
	    margin-bottom: 40px;
    	letter-spacing: 10px;
			@include media-breakpoint-down(xs) {
				font-size: 30px;
		    margin-bottom: 20px;
			}
		}
		&__02 {
			font-size: 18px;
			color: #fff;
			display: block;
    	letter-spacing: 4px;
			@include media-breakpoint-down(xs) {
	    	letter-spacing: 3px;
				font-size: 10px;
			}
		}
		&__03 {
			font-size: 14px;
			color: #fff;
			display: block;
    	letter-spacing: 4px;
			@include media-breakpoint-down(xs) {
	    	letter-spacing: 3px;
				font-size: 10px;
			}
		}
	}
	&__sp {
		display: none;
		@include media-breakpoint-down(xs) {
			display: block;
			background: url('../images/bg_hero_sp.jpg') no-repeat center center;
			width: 100%;
			height: 100vh;
			background-size: cover;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// hero - video
//
//
.yt {
	// position: fixed;
	position: relative;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;
	overflow: hidden;
	background: #000;
	@include media-breakpoint-down(xs) {
		display: none;
	}
	#video {
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
	}
	&::before {
		// position: fixed;
		position: relative;
		top: 0;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.1);
		z-index: 1;
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// footer
//
//
.footer {
	width: 100%;
	height: 278px;
	background: url('../images/img_footer-bg.jpg') no-repeat top center;
	background-size: cover;
	@include media-breakpoint-down(xs) {
		height: 139px;
	}
	&__inner {
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.copyright {
		font-size: 14px;
		color: #fff;
		text-align: center;
		display: block;
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// main
//
//
.main {
	display: block;
	// &__inner {
	// }
}
.contents {
	background-color: #fafbfc;
	&:nth-child(even) {
		background-color: #edf2f5;
	}
	&.section-outline {
		background-color: #fff;
	}
	&__header {
		&__inner {
			width: $base-width;
			margin: 0 auto;
			padding: 140px 0 0 0;
			@include media-breakpoint-down(xs) {
				width: 100%;
				padding: 70px 20px 0 20px;
			}
		}
	}
	&__body {
		margin-top: 40px;
		&__inner {
			width: $base-width;
			margin: 0 auto;
			padding: 0 0 140px 0;
			@include media-breakpoint-down(xs) {
				width: 100%;
				padding: 0 0 70px 0;
			}
		}
		&__inner--600 {
			max-width: 600px;
		}

		&__mainimg {
			img {
				max-width: 100%;
				height: auto;
				display: block;
			}
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// outline
//
//
.outline-lists-wrapper {
	margin-top: 60px;
	@include media-breakpoint-down(xs) {
		margin-top: 0;
		padding: 0 20px;
	}
}
.outline-list {
	position: relative;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 20px;
	font-size: 16px;
	& + .outline-list {
		margin-top: 20px;
	}
	&__name {
		position: absolute;
		width: 290px;
		vertical-align: top;
		@include media-breakpoint-down(xs) {
			position: static;
			width: 100%;
		}
	}
	&__detail {
		display: inline-block;
		padding-left: 380px;
		vertical-align: top;
		@include media-breakpoint-down(xs) {
			display: block;
			padding-left: 0;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// actor
//
//
.actor-person {
	&__img {
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&__content {
		&__detail {
			margin-top: 20px;
			font-size: 16px;
		}
	}
	&__info {
		font-weight: normal;
		margin-top: 40px;
		margin-bottom: 0;
		&__name {
			font-size: 36px;
			display: block;
			span {
				display: block;
				font-size: 16px;
				padding: 4px 0 0 0;
			}
		}
		&__other {
			font-size: 16px;
			display: block;
		}
		&__name + &__other {
			margin-top: 10px;
		}
	}
}
.actor-lists {
	display: flex;
	flex-wrap: wrap;
	.actor-list {
    width: 46%;
    margin: 0 10px 20px 10px;
		@include media-breakpoint-down(xs) {
			width: 46%;
			margin: 0 6px 10px 6px;
		}
		a {
			color: #000;
			&:hover {
				color: #000;
				text-decoration: none;
			}
		}
		&__img {
			width: 240px;
			@include media-breakpoint-down(xs) {
				width: 100%;
				height: auto;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}
		&__name {
			margin-top: 10px;
			@include media-breakpoint-down(xs) {
				margin-top: 5px;
			}
		}
		&__hidden {
			display: none;
		}
	}
}



///////////////////////////////////////////////////////////////////////////////////////////
//
//
// form
//
//
.form-wrapper {
		max-width: 100%;
		padding: 30px;
		margin: 0 auto;
		background-color: #f3f3f3;
}
.form-header {
	margin-bottom: 20px;
}
.form-header__ttl {
}
.form-item + .form-item {
		margin-top: 20px;
}
.form-item + .form-item__button {
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	text-align: center;
}
.form-item__required {
		// background-color: #DE3C4B;
		background-color: #C3423F;
		color: #fff;
		padding: 4px 10px;
		margin-left: 10px;
		font-size: 12px;
		vertical-align: text-bottom;
}
// .form-item__button {
// 		margin: 0;
// 		padding: 0;
// 		font-size: 100%;
// 		border: none;
// 		outline: 0;
// 		color: #fff;
// 		background-color: #61c6e7;
// 		padding: 20px 30px;
// 		font-weight: bold;
// 		border-radius: 10px;
// 		display: block;
// 		box-sizing: border-box;
// 		-webkit-transition: all .1s linear;
// 		transition: all .1s linear;
// }
// .form-item__button:hover {
// 		opacity: .8;
// 		-webkit-transition: all .1s linear;
// 		transition: all .1s linear;
// }
.form-item__name {
		margin-bottom: 10px;
}
.form-item__input input,
.form-item__input textarea
{
		margin: 0;
		padding: 0;
		border: none;
		outline: 0;
}
.form-item__input input,
.form-item__input textarea
{
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		padding: 10px;
		border-radius: 10px;
		border-color: transparent;
		border: 2px solid #c5c5c5;
		box-sizing: border-box;
		-webkit-transition: border-color 0.6s linear;
		transition: border-color 0.6s linear;
}
.form-item__input input:focus,
.form-item__input textarea:focus
{
		-webkit-transition: border-color 0.6s linear;
		transition: border-color 0.6s linear;
		border-color: #0080cc;
}

.form-item__input textarea {
		height: 200px;
		max-height: 500px;
}



///////////////////////////////////////////////////////////////////////////////////////////
//
// plugin
// - colorbox
//
.popup {
	&__item {
		display: none;
	}
}


///////////////////////////////////////////////////////////////////////////////////////////
//
// plugin
// - swiper
//
// .section-slide {
	// @include mq(md) {
	// 	overflow: hidden;
	// }
// }
.section-slide-a {
	overflow: visible;
	text-align: center;
	@include media-breakpoint-down(xs) {
		overflow: hidden;
	}
	&__inner {
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}
	.swiper-slide {
		@include box-shadow(rgba(0, 0, 0, 0.2) 20px 20px 20px 0);
		img {
			width: 100%;
			height: auto;
		}
	}
}

.swiper-pagination {
	position: relative;
	margin-top: 90px;
	@include media-breakpoint-down(xs) {
		margin-top: 45px;
	}
	.swiper-pagination-bullet {
		width: 16px;
		height: 16px;
		background: #cbcbcb;
		opacity: 1;
		outline: none;
		@include media-breakpoint-up(xs) {
			margin: 0 20px !important;
		}
		@include media-breakpoint-down(xs) {
			margin: 0 10px !important;
		}
		&.swiper-pagination-bullet-active {
			background: #61c6e7;
		}
	}
}


#colorbox iframe {
    width: 560px;
		// height: 315px;
		@include media-breakpoint-down(xs) {
			width: 300px;
			}
}

.news_list {
	@include media-breakpoint-down(xs) {
			padding: 0 20px;
			}
	li {
		margin-bottom: 4px;
		.date {
			margin-right: 20px;
		}
		a {
			color: #000;
		}
	}
}

.news_article {
	p {
		margin-bottom: 20px;
	}
	.attention {

	}
	a {
		color: #61c6e7;
		&:hover {
			text-decoration: underline;
		}
	}
}


.about_list {
	margin-bottom: 2em;
	li {
		margin-bottom: 8px;
	}
	h4 {
		font-weight: bold;
		margin-bottom: -2px;
	}
	p {
		font-size: 12px;
	}
}

.popup-movie {
	position: relative;
	&:after {
		content: "";
		display: block;
		width: 120px;
		height: 120px;
		position: absolute;
		top: -230px;;
		left: 200px;
		background: url(../images/img_movie_play.png) no-repeat 0 0;
		background-size: 100%;
		@include media-breakpoint-down(xs) {
			width: 100px;
			top: -130px;;
			left: 93px;
			}
	}
}


.list-banner {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 100px;
	padding-bottom: 100px;
	border-top: 1px solid #cccccc;
	.item {
		margin: 0 10px;
		a {
			display: block;
			width: 220px;
			border: 1px solid #cccccc;
			padding: 10px 20px;
		}
		img {
			width: 100%;
		}

	}
}


@keyframes fadein {
	from {
			opacity: 0;
	}
	to {
			opacity: 1;
	}
}

.banner-top {
	position: absolute;
	bottom: 40px;
	left: 40px;
	z-index: 100;
	animation-name: fadein;
	animation-duration: 5s;
	animation-iteration-count: 1;
	@include media-breakpoint-down(xs) {
		bottom: 70px;
		left: 20px;
	}
	&__list {
		img {
			width: auto;
			height: 60px;
			@include media-breakpoint-down(xs) {
				width: auto;
				height: 40px;
			}
		}
	}
}

.homeBanner {
	margin-bottom: 40px;
	a {
		display: block;
		margin-bottom: 20px;
	}
	img  {
		max-width: 100%;
	}
}