.mt-0 {
	margin-top: 0;
}
.mr-0 {
	margin-right: 0;
}
.mb-0 {
	margin-bottom: 0;
}
.ml-0 {
	margin-left: 0;
}
@for $i from 1 through 16 {
	.mt-#{$i * 5} {
		margin-top: $i * 5px;	
	}
	.mr-#{$i * 5} {
		margin-right: $i * 5px;
	}
	.mb-#{$i * 5} {
		margin-bottom: $i * 5px;
	}
	.ml-#{$i * 5} {
		margin-left: $i * 5px;
	}
}
@include media-breakpoint-down(xs) {
	.mt-0-sp {
		margin-top: 0;
	}
	.mr-0-sp {
		margin-right: 0;
	}
	.mb-0-sp {
		margin-bottom: 0;
	}
	.ml-0-sp {
		margin-left: 0;
	}
	@for $i from 1 through 16 {
		.mt-#{$i * 5}-sp {
			margin-top: $i * 5px;
		}
		.mr-#{$i * 5}-sp {
			margin-right: $i * 5px;
		}
		.mb-#{$i * 5}-sp {
			margin-bottom: $i * 5px;
		}
		.ml-#{$i * 5}-sp {
			margin-left: $i * 5px;
		}
	}
}

