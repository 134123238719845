*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
	&::selection {
		background-color: #61c6e7;
		color: #fff;
	}
	&::-moz-selection {
		background-color: #61c6e7;
		color: #fff;
	}
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  color: $black;
  background-color: #fff;
}
body {
  font: $font-size/#{$line-height} $font-family;
  //min-width: $base-width + ($side-space * 2);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.7px;
  // font-feature-settings : "palt";
  @include media-breakpoint-down(xs) {
    min-width: inherit;
    font-size: 11px;
  }
}

[tabindex="-1"]:focus {
  outline: none !important;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  &:last-child {
    margin-bottom: 0;
  }
}
p {
  margin-top: 0;
  margin-bottom: 2em;
  &:last-child {
    margin-bottom: 0;
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
}


hr {
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

address {
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  list-style: none;
  margin: 0;
  padding: 0;
  //margin-top: 0;
  //margin-bottom: 1em;
  &:last-child {
    //margin-bottom: 0;
  }
}

ol ol,
ul ul,
ol ul,
ul ol {
  list-style: none;
  margin-bottom: 0;
}

ol, ul {
  //padding-left: 1.5em;
}

dt {
  font-weight: normal;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  // @include media-breakpoint-up(sm) {
    &:hover {
      // color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  // }
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
  &:focus {
    outline: 0;
  }
}

em {
  font-style: normal;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: bottom;
}

[role="button"] {
  cursor: pointer;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: #fff;
}

caption {
  display: none;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  -webkit-appearance: none;
  background: none;
  border-width: 0;
}

button:focus {
  outline: 0;
  // outline: 1px dotted;
  // outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  line-height: inherit;
}

input[type="text"], input[type="search"] {
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    color: #333;
    padding: 10px;
}

input[type="radio"],
input[type="checkbox"] {
  &:disabled {
    cursor: not-allowed;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

input[type="search"] {
  -webkit-appearance: none;
}


[hidden] {
  display: none !important;
}
