.only-pc {
	@include media-breakpoint-down(xs) {
		display: none !important;
	}
}
.only-sp {
	@include media-breakpoint-up(sm) {
		display: none !important;
	}
}

// @include media-breakpoint-up(sm) {
// 	pcのみ反映用
// }

// @include media-breakpoint-down(xs) {
// 	spのみ反映用
// }