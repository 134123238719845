
// Fonts
//////////////////////////////////////////////////////////
$font-size: 14px !default;
$line-height: 1.7 !default;
$font-family: 'Sawarabi Mincho', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, sans-serif !default;
// $font-family-serif: "Ryumin Light KL", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif !default;
$font-family-serif: YuMincho, "YuMincho", serif !default;

// path
//////////////////////////////////////////////////////////
$path: "/" !default;

// Width
//////////////////////////////////////////////////////////
$base-width: 1080px !default;
$content-width: 1080px !default;




// Colors
//////////////////////////////////////////////////////////
$white:  #fff !default;
$black:  #000 !default;
$blue:   #077877 !default;
$gray:   #f9f9f9 !default;
$darkblue:   #00175a !default;

// Horizontal rules
//////////////////////////////////////////////////////////
$hr-border-color: rgba($black,1) !default;
$hr-border-width: 1px !default;







$red:    #ff0028 !default;
$orange: #f18d00 !default;
$yellow: #ffd500 !default;
$green:  #0dac67 !default;

$teal:   #e5f6fd !default;
$pink:   #ff5381 !default;
$purple: #613d7c !default;

// Create grayscale
$gray-dark:                 #323232 !default;

$gray-light:                #636c72 !default;
$gray-lighter:              #c8c8c8 !default;
$gray-lightest:             #f8f8f8 !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $blue !default;
$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;


$link-color:            #2d75c7 !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// header-line
$header-line-color: #000000 !default;
$header-line-color_hv: #ffffff !default;
$header-line-width: 2px;


// acticles
//////////////////////////////////////////////////////////
$acticles-border-width: 1px !default;
$acticles-border-color:  #000000 !default;


// pager
//////////////////////////////////////////////////////////
$pager-border-width: 2px !default;
$pager-border-color:  #717171 !default;
$pager-color:  #ffffff !default;
$pager-border-color-active:  #000000 !default;
$pager-color-active:  #000000 !default;


// Breakpoints
//////////////////////////////////////////////////////////
$grid-breakpoints: (
	xs: 0,
	sm: 769px,
	lg: 992px,
	xl: 1200px
) !default;







// Spacer
//////////////////////////////////////////////////////////
$spacer:   1em !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$side-space: 20px !default;
$side-space-s: 17px !default;











// border radius
//////////////////////////////////////////////////////////
$border-radius: 4px !default;




// grid
//////////////////////////////////////////////////////////
$gutter: 24px;
$gutter-s: 14px;
