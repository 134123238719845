.pt-0 {
	padding-top: 0;
}
.pr-0 {
	padding-right: 0;
}
.pb-0 {
	padding-bottom: 0;
}
.pl-0 {
	padding-left: 0;
}
@for $i from 1 through 16 {
	.pt-#{$i * 5} {
		padding-top: $i * 5px;
	}
	.pr-#{$i * 5} {
		padding-right: $i * 5px;
	}
	.pb-#{$i * 5} {
		padding-bottom: $i * 5px;
	}
	.pl-#{$i * 5} {
		padding-left: $i * 5px;
	}
}

@include media-breakpoint-down(xs) {
	.pt-0-sp {
		padding-top: 0;
	}
	.pr-0-sp {
		padding-right: 0;
	}
	.pb-0-sp {
		padding-bottom: 0;
	}
	.pl-0-sp {
		padding-left: 0;
	}
	@for $i from 1 through 16 {
		.pt-#{$i * 5}-sp {
			padding-top: $i * 5px;
		}
		.pr-#{$i * 5}-sp {
			padding-right: $i * 5px;
		}
		.pb-#{$i * 5}-sp {
			padding-bottom: $i * 5px;
		}
		.pl-#{$i * 5}-sp {
			padding-left: $i * 5px;
		}
	}
}
